import React, { useContext } from "react";
import styled from "styled-components";
import { Tools } from "react-sketch2";
import { GithubPicker } from "react-color";

import ToggleButtonRound from "../../elements/ToggleButtonRound";
import { CanvasContext } from "../../../store/context/CanvasContext";
import ButtonRound from "../../elements/ButtonRound";

import backIcon from "../../../assets/icons/back.png";
import ellipseIcon from "../../../assets/icons/ellipse.png";
import squareIcon from "../../../assets/icons/square.png";
import lineIcon from "../../../assets/icons/line.png";
import freehandIcon from "../../../assets/icons/freehand.png";

const Container = styled.div`
  margin: 0 !important;
`;

const GithubPickerStyled = styled(GithubPicker)`
  position: absolute !important;
  top: -62px;
  width: 220px !important;
  left: 0;
  z-index: 10;
`;

const ToggleButtonRoundPicker = styled(ToggleButtonRound)`
  position: relative;
`;

const ColorIndicator = styled.div`
  background: ${({ color }) => color || "black"};
  width: 30px;
  height: 30px;
`;

const DrawingTools = ({ className }) => {
  const [showColorPicker, setShowColorPicker] = React.useState(false);
  const [, setUnCheckColorPicker] = React.useState(false);
  const canvasContext = useContext(CanvasContext);
  const { setTool, tool, undo, color, setColor } = canvasContext;

  const onToggleButton = React.useCallback(
    (type) => (val) => {
      return val && setTool(type);
    },
    [tool]
  );

  const toUncheck = React.useCallback(
    (type) => () => {
      return tool !== type;
    },
    [tool]
  );

  const onColorChange = React.useCallback((color) => {
    setColor(color.hex);
    setShowColorPicker(false);
    setUnCheckColorPicker(true);
  }, []);

  const onToggleColorPicker = React.useCallback(() => {
    setShowColorPicker(!showColorPicker);
  }, [showColorPicker]);

  const toUncheckColorPicker = React.useCallback(() => !showColorPicker, [
    showColorPicker,
  ]);

  return (
    <Container className={className}>
      <ToggleButtonRoundPicker
        onToggle={onToggleColorPicker}
        unCheck={toUncheckColorPicker}
        iconSrc=""
        tooltipTitle="select color"
        onlyCheck
      >
        <ColorIndicator color={color} />
        {showColorPicker && (
          <GithubPickerStyled
            color={color}
            onChangeComplete={onColorChange}
            triangle="hide"
          />
        )}
      </ToggleButtonRoundPicker>
      <ToggleButtonRound
        onToggle={onToggleButton(Tools.Circle)}
        unCheck={toUncheck(Tools.Circle)}
        iconSrc={ellipseIcon}
        tooltipTitle="ellipse"
        onlyCheck
      />
      <ToggleButtonRound
        onToggle={onToggleButton(Tools.Rectangle)}
        unCheck={toUncheck(Tools.Rectangle)}
        iconSrc={squareIcon}
        tooltipTitle="square"
        onlyCheck
      />
      <ToggleButtonRound
        onToggle={onToggleButton(Tools.Line)}
        unCheck={toUncheck(Tools.Line)}
        iconSrc={lineIcon}
        tooltipTitle="line"
        onlyCheck
      />
      <ToggleButtonRound
        checked
        onToggle={onToggleButton(Tools.Pencil)}
        unCheck={toUncheck(Tools.Pencil)}
        iconSrc={freehandIcon}
        tooltipTitle="freehand"
        onlyCheck
      />
      {/* color selector */}
      <ButtonRound
        onClick={() => undo()}
        iconSrc={backIcon}
        tooltipTitle="back"
      />
    </Container>
  );
};

export default DrawingTools;
