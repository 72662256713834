import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { Formik, Form } from "formik";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { StreamingContext } from "../store/context/StreamingContext";
import { authOperations } from "../store/redux/auth";
import { authSelectors } from "../store/redux/auth";

import TextInput from "../components/elements/TextInput";
import Button from "../components/elements/Button";
import Logo from "../components/elements/Logo";
import theme from "../assets/styles/theme";
import ToggleButtonRound from "../components/elements/ToggleButtonRound";

//TODO: change way to show version of project
import packageJson from "../../package.json";

import microphoneOnIcon from "../assets/icons/microphone-on.png";
import microphoneOffIcon from "../assets/icons/microphone-off.png";
import CameraIcon from "../assets/icons/camera.png";
import noCameraIcon from "../assets/icons/no-camera.png";

import { LINE_SIZE } from "../assets/styles/theme";
const NAMEREGEX = /^[a-z0-9.]{2,30}$/i;
const PINREGEX = /^[0-9]{2,15}$/i;

const ContentWrapper = styled.div`
  @media screen and (max-width: 1280px) {
    position: absolute;
    right: 0;
    width:70%;
    padding: 1em;
    text-align: center;
  }

  @media screen and (max-width: 640px) {
    top: 200px;
    width: 100%;
  }

  @media not screen and (max-width: 1280px) {
    display: flex;
    box-sizing: border-box;
    justify-content: flex-start;
    flex-direction: column;
    padding: ${LINE_SIZE * 2}px ${LINE_SIZE * 3.5}px;
  }

  > * {
    margin-bottom: 11.6px;
  }

  .last-item {
    margin-bottom: 15px;
  }

  background: white;

  .invalid-feedback {
    border: 1px solid red;
    background: #fadee3;
    color: red;
    text-align: center;
  }

  .mediaAllowedCheckboxWrap {
    margin: 0 auto;
  }
`;

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;

  @media screen and (max-width: 1280px) {
    display: block;
    padding-left: 1em;
  }
`;

const Title = styled.label`
  font-size: 90px;
  font-weight: ${theme.fontWeightNormal};
  text-align: center;
  color: ${theme.fontColorPrimary};

  @media screen and (max-width: 1280px) {
    font-size: 5em;
  }

  @media screen and (max-width: 640px) {
    font-size: 3em;
  }
`;

const CenterElementWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SubmitButton = styled(Button)`
  width: 60%;
  margin: auto;
`;

const INITIAL_FORM_VALUES = {
  expertNickname: "",
  pin: "",
};
const INITIAL_FORM_ERRORS = {
  expertNickname: "",
  pin: "",
};

const TitleText = styled.div`
  text-align: center;
`;

const TextStyled = styled.div`
  color: ${theme.fontColorPrimary};
  margin-bottom: 32px;
`;

const VersionLabel = styled.div`
  text-align: right;
  font-size: 10px;
  //font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  color: ${theme.colorProductDark};

  @media not screen and (max-width: 1280px) {
    top: 50px;
    left: 50px;
    position: relative;
  }
`;

const Login = () => {
  const streamContext = useContext(StreamingContext);
  const { isAudioAllowed } = streamContext.state;
  // const { isVideoAllowed} = streamContext.state;
  const isRequestSuccess = useSelector(authSelectors.getIsRequestSuccess);
  const connectionError = useSelector(authSelectors.getError);
  const connectionLoading = useSelector(authSelectors.getLoading);
  const history = useHistory();
  const dispatch = useDispatch();


  useEffect(() => {
    // console.log("@E@@#@##$#$ isVideoAllowed: ", isVideoAllowed);
    // console.log("@E@@#@##$#$ isAudioAllowed: ", isAudioAllowed);
    if (isRequestSuccess) {
      history.push("/dashboard");
    }
  }, [isRequestSuccess]);

  const checkIsSubmitValid = (values) => {
    const { expertNickname, pin } = values;
    const errors = {};
    if (expertNickname && !NAMEREGEX.test(expertNickname)) {
      errors.expertNickname = "Please recheck expert nickname format (a-Z/0-9)";
    }
    if (pin && !PINREGEX.test(pin)) {
      errors.pin = "Please recheck pin format (number > 10)";
    }
    return errors;
  };

  const onSubmit = async (values) => {
    const { expertNickname, pin } = values;
    if (!expertNickname && !pin) {
      console.log("has error");
    }
    await dispatch(authOperations.logIn(expertNickname, pin));
  };

  const onClickMediaAllowedCheckbox = (checked, name) => {
    // if (name === "isVideoAllowed") {
    //   streamContext.setIsVideoAllowed(checked);
    // }
    if (name === "isAudioAllowed") {
      streamContext.setIsAudioAllowed(checked);
    }
  };

  //TODO: create loader while doing request

  return (
    <Container>
      {connectionLoading && <p>Loader...</p>}
      {!connectionLoading && (
        <>
          <Logo />
          <Formik
            initialValues={INITIAL_FORM_VALUES}
            initialErrors={INITIAL_FORM_ERRORS}
            onSubmit={onSubmit}
            validate={checkIsSubmitValid}
          >
            {({
              values: { expertNickname, pin },
              errors,
              handleBlur,
              handleChange,
            }) => {
              return (
                <Form translate="something" autoComplete={"new-password"}>
                  <ContentWrapper>
                    <TitleText>
                      <Title>
                        STREAMING
                        <br /> PORTAL
                      </Title>
                    </TitleText>
                    <TextInput
                      name="expertNickname"
                      defaultValue={expertNickname}
                      label={"Expert Nickname"}
                      error={!!errors.expertNickname}
                      onChange={handleChange}
                      disabled={connectionLoading}
                      onBlur={handleBlur}
                      required
                    />
                    <TextInput
                      name="pin"
                      defaultValue={pin}
                      label={"Pin"}
                      error={!!errors.pin}
                      onChange={handleChange}
                      disabled={connectionLoading}
                      onBlur={handleBlur}
                      autoComplete={"off"}
                      required
                      className="last-item"
                    />
                    <CenterElementWrapper>
                      <TextStyled>
                        The pin must be provided by the smart unit user.
                      </TextStyled>
                    </CenterElementWrapper>
                    <CenterElementWrapper>
                      <SubmitButton type="submit" disabled={connectionLoading}>
                        Log in
                      </SubmitButton>
                    </CenterElementWrapper>
                    {/*{inputError && (*/}
                    {/*  <div className="invalid-feedback">*/}
                    {/*    <p>*/}
                    {/*      "Nickname or pin not valid. Please check and try*/}
                    {/*      again"*/}
                    {/*    </p>*/}
                    {/*  </div>*/}
                    {/*)}*/}
                    {connectionError && (
                      <div className="invalid-feedback">
                        <p>{connectionError || "Please enter a pin."}</p>
                      </div>
                    )}
                    <div className="mediaAllowedCheckboxWrap">
                      <ToggleButtonRound
                        checked={isAudioAllowed}
                        iconSrc={microphoneOffIcon}
                        switchIconSrc={microphoneOnIcon}
                        onToggle={(checked) =>
                          onClickMediaAllowedCheckbox(checked, "isAudioAllowed")
                        }
                        iconSize="large"
                        tooltipTitle={`microphone is ${isAudioAllowed ? "on" : "off"
                          }`}
                      />
                      {/*<ToggleButtonRound*/}
                      {/*  checked={isVideoAllowed}*/}
                      {/*  iconSrc={noCameraIcon}*/}
                      {/*  switchIconSrc={CameraIcon}*/}
                      {/*  onToggle={(checked) =>*/}
                      {/*    onClickMediaAllowedCheckbox(checked, "isVideoAllowed")*/}
                      {/*  }*/}
                      {/*  iconSize="large"*/}
                      {/*  tooltipTitle={`camera is ${*/}
                      {/*    isVideoAllowed ? "on" : "off"*/}
                      {/*  }`}*/}
                      {/*/>*/}
                    </div>
                    <VersionLabel>{packageJson.version}</VersionLabel>
                  </ContentWrapper>
                </Form>
              );
            }}
          </Formik>
        </>
      )}
    </Container>
  );
};

export default Login;
