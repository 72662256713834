import React, { useContext } from "react";
import styled, { css } from "styled-components";
import { SketchField } from "react-sketch2";
import { useMeasure } from "react-use";

import { CanvasContext } from "../../../store/context/CanvasContext";
import { dashboardShadow } from "../../../assets/styles/theme";
import ButtonRound from "../../elements/ButtonRound";
import { resizeImg } from "../../../services/resizeImg";

import closeIcon from "../../../assets/icons/close.png";

const StyledCanvas = styled.div.attrs({ className: "canvas" })`
  width: 100%;
  height: calc(100% - 60px);
  overflow: hidden;
  position: relative;
  ${({ isHidden }) =>
    isHidden
      ? css`
          display: none;
        `
      : css`
          display: inherit;
        `}
  .upper-canvas {
    ${dashboardShadow}
  }
`;

/* const MaximiseCanvasButton = styled(ButtonRound)`
  top: 0;
  right: 45px;
  height: 60px;
  position: absolute !important;
  z-index: 1;
`; */

const ExitCanvasButton = styled(ButtonRound)`
  top: 0;
  right: 0;
  height: 60px;
  position: absolute !important;
  z-index: 1;
`;

export const sleep = (ms) =>
  new Promise((res) => {
    setTimeout(res, ms);
  });

export const waitFor = async (predicate, ms = 10, timeout = 5000) => {
  let timePassed = 0;
  while (!predicate()) {
    if (timePassed >= timeout) {
      Promise.reject();
    }
    await sleep(ms);
    timePassed += ms;
  }
};

const Canvas = ({ className, isHidden }) => {
  const canvasContext = useContext(CanvasContext);
  const {
    startUndo,
    setUndo,
    tool,
    sketchRef,
    setImage,
    image,
    canvasSize,
    setCanvasSize,
    setIsDrawingOpen,
    isSideBarOpen,
    setIsSideBarOpen,
    color,
  } = canvasContext;

  const [CHANGE_ME_Ref, { width, height }] = useMeasure();
  const [oldSize, setOldSize] = React.useState({});

  React.useEffect(() => {
    if (typeof width === "number" && typeof height === "number") {
      setCanvasSize({ width, height });
    }
    // TODO: not working on resize of window
  }, [width, height]);

  React.useEffect(() => {
    if (startUndo) {
      if (sketchRef.current.canUndo()) {
        sketchRef.current.undo();
      }
      setUndo(false);
    }
  }, [startUndo]);

  React.useEffect(() => {
    if (image && sketchRef.current) {
      // todo review if waitfor function can be refactored (removed completely) FIX HACK
      waitFor(() => sketchRef.current.props.height).then(() => {
        const imageEl = new Image();
        if (!imageEl) {
          console.log("Image missing");
          return;
        }
        imageEl.onload = () => {
          resizeImg(
            (img) => {
              sketchRef.current.setBackgroundFromDataUrl(img);
            },
            {
              dataUrl: image,
              clientHeight: sketchRef.current.props.height,
              clientWidth: sketchRef.current.props.width,
              ratio: imageEl.width / imageEl.height,
            }
          );
          sketchRef.current._backgroundColor("#fff");
          setImage(null);
        };
        imageEl.src = image;
      });
    }
  }, [image, sketchRef]);

  const exitCanvas = React.useCallback(() => {
    sketchRef.current.clear();
    setIsDrawingOpen(false);
    setImage(null);
  }, []);

  /*  const maximiseCanvas = React.useCallback(() => {
    if (isSideBarOpen) {
      setOldSize({ width, height });
    }
    if (!isSideBarOpen && oldSize.width && oldSize.height) {
      setCanvasSize({ width: oldSize.width, height: oldSize.height });
    }
    setIsSideBarOpen(!isSideBarOpen);
  }, [isSideBarOpen, width, height]); */

  return (
    <StyledCanvas ref={CHANGE_ME_Ref} className={className} isHidden={isHidden}>
      {/*/!* <MaximiseCanvasButton iconSrc="/expand.png" onClick={maximiseCanvas} /> *!/*/}
      {!isHidden && (
        <ExitCanvasButton
          iconSrc={closeIcon}
          onClick={exitCanvas}
          tooltipTitle="close"
        />
      )}
      <SketchField
        width={canvasSize.width}
        widthCorrection={0}
        height={canvasSize.height}
        tool={tool}
        lineColor={color}
        lineWidth={3}
        ref={sketchRef}
        backgroundColor="#fff"
      />
    </StyledCanvas>
  );
};

export default Canvas;
